import React, { createContext, useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import { Q_CURRENT_USER } from "../apollo/actions/users";

const EntitlementContext = createContext(null);

export const EntitlementProvider = ({ userId, children }) => {
  const { data, loading, error } = useQuery(Q_CURRENT_USER, {
    variables: { id: userId },
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <p>Loading user data...</p>;
  if (error) return <p>Error loading user data</p>;

  return (
    <EntitlementContext.Provider value={data && data.currentUser && data.currentUser.entitlements || []}>
      {children}
    </EntitlementContext.Provider>
  );
};

export const useEntitlements = () => {
  return useContext(EntitlementContext);
};
